import React from 'react';
import './HomePage.css';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import WhatsAppIcon from '../../components/icons/WhatsAppIcon';
import InstagramIcon from '../../components/icons/InstagramIcon';
import FacebookIcon from '../../components/icons/FacebookIcon';
import EmailIcon from '../../components/icons/EmailIcon';
import SplashShape from '../../components/shapes/SplashShape';


const Logo = styled.img`
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  height: 100%;
  max-width: 700px;
`;

const ContactsWrapper = styled(Grid)`
  margin-top: 50px;
  margin-bottom: 50px;
  align-content:center;
`;

const BrownButton = styled(Button)`
  margin: 10px;
  background-color: #cd936b;
  border-radius: 5px;
  color: #ffffff;
  min-width: 220px;
  font-family: Nunito;
`

const Text = styled.p`
  font-family: Nunito;
  text-align: center;
`;

const SplashTopRightCorner = styled(SplashShape)`
  position: fixed;
  right:0vw;
  top: -60px;
  height: 393px;
  width: 335px;
  z-index: -1;
  overflow-x: hidden;
  display: flex;
`;

const SplashBottomLeftCorner = styled(SplashShape)`
  position: fixed;
  left:0vw;
  bottom: -60px;
  height: 393px;
  width: 335px;
  z-index: -1;
  overflow-x: hidden;
  display: flex;
  -webkit-transform: scaleX(-1) scaleY(-1);
  transform: scaleX(-1) scaleY(-1);
  
`;


const Groomers = styled(Text)`
  font-weight: bold;
  font-size: 30px
`;


function HomePage() {
  return (
    <>
      <SplashTopRightCorner />
      <SplashBottomLeftCorner />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        width="100vw"
      >
        <Logo
          src={"logo.png"}
          alt={"Brush Your Pet"}
          loading="lazy"
        />
        <Groomers>Catarina & Soraia</Groomers>
        <Text>Pet Grooming for Pets and Show Dogs.</Text>
        <Text>Since 2020 using Stress Free Methods and Positive Technics for the Best
          Grooming Results.</Text>
        <Text>Internationally trained groomers and all around pet lovers.</Text>
        <ContactsWrapper
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center">
          <BrownButton href="https://www.instagram.com/brush.your.pet/" endIcon={<InstagramIcon />} >Instagram</BrownButton>
          <BrownButton href="https://wa.me/913055706" endIcon={<WhatsAppIcon />} >WhatsApp Catarina</BrownButton>
          <BrownButton href="https://wa.me/967692980" endIcon={<WhatsAppIcon />} >WhatsApp Soraia</BrownButton>
          <BrownButton href="https://www.facebook.com/profile.php?id=100066399206397" endIcon={<FacebookIcon />}>Facebook</BrownButton>
          <BrownButton href="mailto:brushyourpet.grooming@gmail.com" endIcon={<EmailIcon />}>Email</BrownButton>
        </ContactsWrapper>

      </Grid>
    </>

  );
}

export default HomePage;
